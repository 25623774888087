import React from 'react'
import { useSelector } from 'react-redux'
import { selectAllCart } from '../../store/slice/cart'
import Card from './Card/Card'
import './cart.scss'
import useGetInitData from '../../hooks/useGetInitData'

const Cart = () => {
  const allCart = useSelector(selectAllCart)

  useGetInitData()

  return (
    <div className="cart__wrapper">
      {allCart.map((item) => (
        <Card key={item.id} id={item.id} name={item.name} price={item.price} />
      ))}
    </div>
  )
}

export default Cart
