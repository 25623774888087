import { useEffect, useState } from 'react'
import {
  erc20ABI, useContract, useFeeData, useSigner,
} from 'wagmi'
import BN from 'bignumber.js'
import { idForCalculateCommission, OUR_CONTRACT_ADDRESS, USDT_CONTRACT_ADDRESS } from '../const'
import { contractAbi } from '../abi'
import api from '../api'

const useGetFee = () => {
  const [objFee, setObjFee] = useState({
    usdt: 0,
    bnb: 0,
  })
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [localIsLoading, setLocalIsLoading] = useState(true)
  const [localError, setLocalError] = useState()

  const objSigner = useSigner()
  const { data: signer } = objSigner

  const contractApprove = useContract({ abi: erc20ABI, address: USDT_CONTRACT_ADDRESS, signerOrProvider: signer })
  const contractPay = useContract({ abi: contractAbi, address: OUR_CONTRACT_ADDRESS, signerOrProvider: signer })

  const {
    data, isError, isLoading, error,
  } = useFeeData({
    formatUnits: 'gwei',
    watch: true,
  })

  useEffect(() => {
    (async () => {
      let usd
      let estimateGasApprove
      let estimateGasPay = 0

      if (isFirstLoading) {
        setLocalIsLoading(true)
      }

      if (objSigner.isLoading || objSigner.isError || !contractApprove.signer || !contractPay.signer || !data) {
        return
      }

      try {
        usd = (await api.exchangeRate.getExchangeRate({ token: 'binancecoin', fiat: 'usd' })).data
      } catch (err) {
        console.error('Get price in usd ', err)

        setLocalError(err)
        return
      }

      try {
        estimateGasApprove = await contractApprove.estimateGas.approve(OUR_CONTRACT_ADDRESS, 1)
      } catch (err) {
        console.error('Estimate gas approve', err)

        setLocalError(err)
        return
      }

      try {
        estimateGasPay = await contractPay.estimateGas.pay(idForCalculateCommission)
      } catch (err) {
        console.error('Estimate gas pay', err)

        setLocalError(err)
        return
      }

      const totalGas = estimateGasPay.add(estimateGasApprove).mul(+data.formatted.gasPrice).toString()
      const bnbCommission = new BN(totalGas).div(1000000000).toString()
      const usdtCommission = new BN(usd).multipliedBy(+bnbCommission).toFixed(2)

      setIsFirstLoading(false)
      setLocalIsLoading(false)
      setObjFee({
        usdt: usdtCommission,
        bnb: bnbCommission,
      })
    })()
  }, [
    contractApprove.estimateGas,
    contractApprove.signer,
    contractPay.estimateGas,
    contractPay.signer,
    data,
    isFirstLoading,
    objSigner.isError,
    objSigner.isLoading,
  ])

  useEffect(() => {
    setLocalError(error)
  }, [error])

  return {
    fee: objFee,
    isLoading: isLoading || localIsLoading,
    isError: isError || localError,
    error: error || localError,
  }
}

export default useGetFee
