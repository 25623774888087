import React from 'react'
import { Link, useParams } from 'react-router-dom'
import notFoundIcon from '../../icons/404.svg'
import './notFound.scss'

const NotFound = () => {
  const { shopId } = useParams()

  return (
    <div className="not-found__wrapper">
      <img className="not-found__icon" src={notFoundIcon} alt="404" />

      <p className="not-found__header">404</p>

      <p className="not-found__description">Ошибка. Страница не найдена</p>

      {shopId && (
      <p className="not-found__action">
        Вернуться в
        {' '}
        <Link to={`/${shopId}`}>бот</Link>
      </p>
      )}
    </div>
  )
}

export default NotFound
