import { Buffer } from 'buffer'

window.global = window
global.Buffer = Buffer
global.process = {
  env: { DEBUG: undefined },
  version: '',
  // eslint-disable-next-line global-require
  nextTick: require('next-tick'),
}
