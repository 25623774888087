/* eslint-disable react/no-danger */
import React, {
  useEffect, useMemo, useReducer, useState,
} from 'react'
import { useSelector } from 'react-redux'
import iconWarning from 'icons/warning.svg'
import { selectAllCatalogs } from '../../store/slice/catalog'
import './catalog.scss'
import Card from './Card/Card'
import Loader from '../../components/Loader/Loader'
import useWebApp from '../../hooks/useWebApp'
import useGetInitData from '../../hooks/useGetInitData'
import { ERROR_TYPE } from './const'
import MainPopupWithoutHeaderAction from '../../components/MainPopupWithoutHeaderAction/MainPopupWithoutHeaderAction'

const initStateForCart = {
  items: [],
  isFinished: false,
}
const reducerForCart = (state, action) => {
  switch (action.type) {
    case 'add':
      return { ...state, items: action.payload }
    case 'finished':
      return { ...state, isFinished: action.payload }
    default:
      return state
  }
}

const Catalog = () => {
  const allCatalog = useSelector(selectAllCatalogs)
  const [error, setError] = useState({ isError: false, message: '', type: '' })
  const [stateCart, dispatchCart] = useReducer(reducerForCart, initStateForCart)
  const [blockList, setBlocks] = useState([])
  const [userInfo, setUserInfo] = useState({})
  const [isOpenTgPopup, setIsOpenTgPopup] = useState(false)
  const tgWebApp = useWebApp()
  const [setIsNeedRefresh, isLoadingInitData] = useGetInitData(dispatchCart, setError, setBlocks, setUserInfo)

  const parseContent = (cont) => {
    let clearCode = cont
    const scriptUrlArr = [...clearCode.matchAll(/<script([^>]+?src[^>]+?)>(<\/script>|\s)/g)]

    if (scriptUrlArr && scriptUrlArr.length > 0) {
      scriptUrlArr.forEach(([full, content]) => {
        clearCode = clearCode.replace(full, '')
        const attrArr = [...content.matchAll(/(\w+?)=["']([^"]+?)["']/g)]
        const scriptElement = document.createElement('script')
        // eslint-disable-next-line prefer-destructuring
        attrArr.forEach((e) => { scriptElement[e[1]] = e[2] })
        try {
          document.head.appendChild(scriptElement)
        } catch (e) {
          clearCode = e.message
        }
      })
    }

    const scriptText = cont.matchAll(/<script>([\w\W]+?)<\/script>/g)
    const scriptTextArr = Array.from(scriptText)

    if (scriptTextArr && scriptTextArr.length > 0) {
      scriptTextArr.forEach(([full, content]) => {
        clearCode = clearCode.replace(full, '')
        let contentScript = content
        const paramsInScript = content.matchAll(/{%{([\w.]+?)}%}/g)
        const paramsInScriptArr = Array.from(paramsInScript)
        if (paramsInScriptArr && paramsInScriptArr.length > 0) {
          paramsInScriptArr.forEach(([mask, param]) => {
            let paramValue = userInfo[param]
            const indexOfDot = param.indexOf('.')
            if (indexOfDot > 0) {
              const paramArr = param.split('.')
              paramValue = userInfo[paramArr[0]][paramArr[1]]
            }

            if (typeof paramValue === 'object' || Array.isArray(paramValue)) {
              paramValue = JSON.stringify(paramValue)
            }
            contentScript = contentScript.replace(mask, paramValue)
          })
        }

        const script = document.createElement('script')
        script.innerHTML = contentScript
        try {
          document.head.appendChild(script)
        } catch (e) {
          clearCode = e.message
        }
      })
    }

    const linkTexArr = [...clearCode.matchAll(/<link([^>]+?)>/g)]

    if (linkTexArr && linkTexArr.length > 0) {
      linkTexArr.forEach(([full, content]) => {
        clearCode = clearCode.replace(full, '')
        const attrArr = [...content.matchAll(/(\w+?)=["']([^"]+?)["']/g)]
        const linkElement = document.createElement('link')
        attrArr.forEach((e) => {
          // eslint-disable-next-line prefer-destructuring
          linkElement[e[1]] = e[2]
        })
        try {
          document.head.appendChild(linkElement)
        } catch (e) {
          clearCode = e.message
        }
      })
    }

    return clearCode
  }

  const renderContent = useMemo(() => {
    if (isLoadingInitData && allCatalog.length === 0 && blockList.length === 0) {
      return (
        <div className="catalog__loading">
          <div className="catalog__loading__box">
            <Loader />
          </div>
        </div>
      )
    }
    if (error.isError) {
      return <div>{`Произошла ошибка ${error.message}`}</div>
    }

    if (allCatalog.length === 0 && blockList.length === 0) {
      return (
        <div className="catalog__empty">
          Товары в каталоге
          <br />
          отсутствуют
        </div>
      )
    }

    if (blockList.length > 0) {
      blockList.filter((e) => (e.type === 'script' && e.tag === 'head'))
        .map((item) => parseContent(item.script))
      // head
      return (
        <div className="catalog__scripts">
          {blockList.filter((e) => (e.type === 'script' && e.tag === 'body'))
            .map((item) => (<div className="catalog__form" dangerouslySetInnerHTML={{ __html: parseContent(item.script) }} />))}
          {blockList.filter((e) => (e.type === 'script' && e.tag === 'footer'))
            .map((item) => (<div dangerouslySetInnerHTML={{ __html: parseContent(item.script) }} />))}
        </div>
      )
    }

    return (
      <div className="catalog__container">
        {allCatalog.map(({ id }) => (
          <Card key={id} id={id} />
        ))}
      </div>
    )
    // eslint-disable-next-line
  }, [allCatalog, error.isError, isLoadingInitData, stateCart.isFinished])

  // Show popup if catch error and is telegram
  useEffect(() => {
    if (error.isError && !isOpenTgPopup && tgWebApp.isTelegram) {
      if (error.type === ERROR_TYPE.catalogIsEmpty) {
        setIsOpenTgPopup(true)

        tgWebApp.utils.showPopup({
          title: 'Ошибка загрузки',
          message: error.message,
          buttons: [{ id: 'ok', text: 'Повторить' }, { id: 'close', text: 'Отмена' }],
        }, (action) => {
          if (action === 'ok') {
            setIsNeedRefresh(true)
            setIsOpenTgPopup(false)
          }

          setError({ isError: false, message: '' })
        })
      }
    }
  }, [isOpenTgPopup, error, tgWebApp, setIsNeedRefresh])

  return (
    <div className="catalog__wrapper">
      <MainPopupWithoutHeaderAction
        isOpen={error.isError && error.type === ERROR_TYPE.paymentNotFound}
        title="Payments"
      >
        <div className="modal-state__container">
          <div className="modal-state__message">
            Платежная система не подключена
          </div>

          <div className="modal-state__state">
            <img className="modal-state__state--icon" src={iconWarning} alt="warning" />
          </div>
        </div>
      </MainPopupWithoutHeaderAction>
      {renderContent}
    </div>
  )
}

export default Catalog
