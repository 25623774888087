import React from 'react'
import PropTypes from 'prop-types'
import './tooltip.scss'

const Tooltip = ({ children, text }) => (
  <div className="tooltip">
    {children}
    <span className="tooltip__content">{text}</span>
  </div>
)

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

export default Tooltip
