import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectCartEntity } from '../../../store/slice/cart'
import { selectCatalogEntity } from '../../../store/slice/catalog'
import './card.scss'
import { checkExistImg } from '../../../utils/files'
import { selectAvailableTypesFiat } from '../../../store/slice/pay'

const Card = ({ id }) => {
  const { count } = useSelector(selectCartEntity(id))
  const selectedByIdProductFromCatalog = useSelector(selectCatalogEntity(id))
  const fiatCurrency = useSelector(selectAvailableTypesFiat)
  const [srcImg, setSrcImg] = useState()

  useEffect(() => {
    checkExistImg(selectedByIdProductFromCatalog.images?.attachment?.filepath)
      .then((res) => setSrcImg(res.srcImg))
      .catch(() => setSrcImg(undefined))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedByIdProductFromCatalog.images?.attachment?.filepath])

  return (
    <div className="card-cart__container">
      <div className="card-cart__left-part">
        {srcImg
          ? <img src={srcImg} alt="The product" className="card-cart__item__img" />
          : <div className="card-cart__item__img card-cart__img--plug" />}

        <div className="card-cart__item__title">{selectedByIdProductFromCatalog.title.slice(0, 21)}</div>
      </div>

      <div className="card-cart__right-part">
        <div className="card-cart__item__count">
          {count}
          x
        </div>

        <div className="card-cart__item__price">
          {selectedByIdProductFromCatalog.price * count}
          {' '}
          {fiatCurrency?.symbol}
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.number.isRequired,
}

export default Card
