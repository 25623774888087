import makeRequest from '../makeRequest'

/**
 * @param {string} token
 * @param {string} fiat
 * @returns {Promise<unknown>|Promise<AxiosResponse<any>>|*}
 */
export const getExchangeRate = ({ token, fiat }) => makeRequest({
  url: `/api/exchange-rate?token=${token}&currency=${fiat}`,
  method: 'GET',
})
