import { isDevelopment } from '../flags'
import { contractAbi, ERC20_ABI } from '../../abi'

export const OUR_CONTRACT_ADDRESS = isDevelopment ? '0x8E4FB0BEAdb90d34677bC44b48B91f41E96F366A' : '0xB4165b060A556e57fbefDc9EF6a03512a8589028'
export const USDT_CONTRACT_ADDRESS = isDevelopment ? '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd' : '0x55d398326f99059fF775485246999027B3197955'
export const BITCOIN_CONTRACT_ADDRESS = isDevelopment ? '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8' : '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
export const BUSD_CONTRACT_ADDRESS = isDevelopment ? '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee' : '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
export const BNB_CONTRACT_ADDRESS = isDevelopment ? '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3' : '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'

export const addressToAbi = {
  [OUR_CONTRACT_ADDRESS.toLowerCase()]: contractAbi,
  [USDT_CONTRACT_ADDRESS.toLowerCase()]: ERC20_ABI,
  [BITCOIN_CONTRACT_ADDRESS.toLowerCase()]: ERC20_ABI,
  [BUSD_CONTRACT_ADDRESS.toLowerCase()]: ERC20_ABI,
  [BNB_CONTRACT_ADDRESS.toLowerCase()]: ERC20_ABI,
}
