import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ERROR_TYPE } from 'pages/Catalog/const'
import { fetchCatalog, selectAllCatalogs } from '../store/slice/catalog'
import { setLocale } from '../store/slice/locale'
import { setAvailableTypes } from '../store/slice/pay'
import useWebApp from './useWebApp'
import { addItem } from '../store/slice/cart'

const useGetInitData = (dispatchCart = () => {}, setError = () => {}, setBlocks = () => {}, setUserInfo = () => {}) => {
  const [isNeedRefresh, setIsNeedRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const allCatalog = useSelector(selectAllCatalogs)
  const dispatch = useDispatch()
  const { shopId } = useParams()
  const tgWebApp = useWebApp()

  useEffect(() => {
    (async () => {
      if ((allCatalog.length === 0 || isNeedRefresh) && tgWebApp.utils.initData) {
        setIsNeedRefresh(false)
        setIsLoading(true)
        try {
          const response = await dispatch(fetchCatalog({
            shopId,
            initData: tgWebApp.utils.initData,
            chatId: tgWebApp.utils.initDataUnsafe.user.id,
          }))

          const {
            payload: {
              webapp: {
                locale, payments, blocks, botId,
              },
              shop: { products, cart },
              userInfo,
            },
          } = response

          setUserInfo({ ...userInfo, botId, botUserId: tgWebApp.utils.initDataUnsafe.user.id })

          const scriptBlocks = blocks.filter((el) => el.type === 'script')

          if (scriptBlocks && scriptBlocks.length > 0) {
            setBlocks(scriptBlocks)
          }

          if (cart && cart.length > 0 && products && products.length > 0) {
            if (!payments) {
              setError({ isError: true, type: ERROR_TYPE.paymentNotFound })

              return
            }

            dispatchCart({ type: 'add', payload: cart })

            cart.forEach((item) => {
              const foundProduct = products.find(({ id }) => id === item.id)

              if (foundProduct) {
                dispatch(addItem({ id: item.id, count: item.amount, price: foundProduct.price * item.amount }))
              }
            })
            dispatchCart({ type: 'finished', payload: true })
          }

          if (locale) {
            dispatch(setLocale(locale))
          }
          if (payments) {
            dispatch(setAvailableTypes(payments))
          }
        } catch (e) {
          console.error(e)

          const message = e.response?.status === 402
            ? 'Что-то пошло не так. Попробуйте позже или вернитесь в чат-бот.'
            : 'Попробовать еще разок?'

          setError({ isError: true, message })
        } finally {
          setIsLoading(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isNeedRefresh, tgWebApp.utils.initData])

  return [setIsNeedRefresh, isLoading]
}

export default useGetInitData
