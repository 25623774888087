import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import OverlayingPopup from '../OverlayingPopup/OverlayingPopup'
import iconClose from '../../icons/cross.svg'
import './main-popup.scss'

const MainPopup = ({
  children,
  onClose,
  isOpen,
  title,
  primaryAction,
  secondaryAction,
}) => {
  const [isShowActionBottom, setIsShowActionBottom] = useState(false)

  useEffect(() => {
    if (primaryAction || secondaryAction) {
      setIsShowActionBottom(true)
    } else {
      setIsShowActionBottom(false)
    }
  }, [primaryAction, secondaryAction])

  return (
    <OverlayingPopup isOpen={isOpen} onClose={onClose}>
      <div className="main-popup__container">
        <div className="main-popup__header">
          {title}

          <div tabIndex="0" role="button" onClick={onClose} className="main-popup__close">
            <img src={iconClose} alt="Close" />
          </div>
        </div>

        <div className="main-popup__content">
          {children}
        </div>

        {isShowActionBottom && (
          <div className="main-popup__action-bottom">
            {secondaryAction && (
              <div onClick={secondaryAction.onClick} className="main-popup__action-bottom__secondary">
                {secondaryAction.text}
              </div>
            )}
            {primaryAction && (
              <div onClick={primaryAction.onClick} className="main-popup__action-bottom__primary">
                {primaryAction.text}
              </div>
            )}
          </div>
        )}
      </div>
    </OverlayingPopup>
  )
}

MainPopup.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  primaryAction: PropTypes.exact({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  secondaryAction: PropTypes.exact({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
}

MainPopup.defaultProps = {
  onClose: () => {},
}
export default MainPopup
