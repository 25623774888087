import makeRequest from '../makeRequest'

/**
 * Get products
 * @param {number} shopId
 * @param {number} chatId
 * @param {string} initData - init data tg
 * @returns {Promise<unknown>|Promise<AxiosResponse<any>>|*}
 */
export const getCatalog = ({ shopId, chatId, initData }) => makeRequest({
  url: `/api/webapp/${shopId}`,
  method: 'POST',
  data: { chatId, initData },
})
