import {
  createSlice, createEntityAdapter, createAsyncThunk, createSelector,
} from '@reduxjs/toolkit'
// import { normalize, schema } from 'normalizr'
import api from '../../api'

// export const catalogEntity = new schema.Entity('articles', {
//   author: userEntity,
//   comments: [commentEntity],
// })

export const catalogAdapter = createEntityAdapter({
  selectId: (item) => item.id,
})

export const fetchCatalog = createAsyncThunk(
  'catalog/getCatalog',
  /**
   * @param {number} shopId
   * @param {number} chatId
   * @param {string} initData - init data tg
   * @returns {Promise<*>}
   */
  async ({ shopId, chatId, initData }) => {
    const { data } = await api.catalog.getCatalog({ shopId, chatId, initData })

    return data
  },
)

export const catalog = createSlice({
  name: 'catalog',
  initialState: catalogAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCatalog.fulfilled, (state, action) => {
      if (action.payload.shop.products) {
        catalogAdapter.upsertMany(state, action.payload.shop.products.sort((a, b) => a.created_at - b.created_at))
      }
    })
    builder.addCase(fetchCatalog.rejected, (state, action) => {
      throw action.error
    })
  },
})

const selectSelf = (state) => state

export const {
  selectById: selectCatalogById,
  selectIds: selectCatalogIds,
  selectEntities: selectCatalogEntities,
  selectAll: selectAllCatalogs,
  selectTotal: selectTotalCatalogs,
} = catalogAdapter.getSelectors((state) => state.catalog)

export const selectCatalogEntity = (id) => createSelector(selectSelf, (state) => selectCatalogById(state, id))
