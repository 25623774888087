import React, { useMemo } from 'react'
import './loader.scss'
import PropTypes from 'prop-types'

const Loader = ({ size, isNoneBackground }) => {
  const clsLoaderContainer = useMemo(() => {
    let cls = 'loader__container'

    cls += ` loader__container--${size}`

    return cls
  }, [size])
  const clsLoaderWrapper = useMemo(() => {
    let cls = 'loader__wrapper'

    if (isNoneBackground) {
      cls += ' loader__wrapper--none-background'
    }

    return cls
  }, [isNoneBackground])

  return (
    <div className={clsLoaderWrapper}>
      <div className={clsLoaderContainer} />
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  isNoneBackground: PropTypes.bool,
}

Loader.defaultProps = {
  size: 'medium',
  isNoneBackground: false,
}

export default Loader
