import React from 'react'
import PropTypes from 'prop-types'
import OverlayingPopup from '../OverlayingPopup/OverlayingPopup'

const MainPopupWithoutHeaderAction = ({
  children,
  isOpen,
  title,
  primaryAction,
}) => (
  <OverlayingPopup isOpen={isOpen}>
    <div className="main-popup__container">
      <div className="main-popup__header">
        {title}
      </div>

      <div className="main-popup__content">
        {children}
      </div>

      {primaryAction && (
        <div className="main-popup__action-bottom">
          <div onClick={primaryAction.onClick} className="main-popup__action-bottom__primary main-popup__action-bottom__width--full">
            {primaryAction.text}
          </div>
        </div>
      )}
    </div>
  </OverlayingPopup>
)

MainPopupWithoutHeaderAction.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  primaryAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
}

export default MainPopupWithoutHeaderAction
