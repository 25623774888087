export const CONST_ROUTER_ID = {
  main: 'main',
  catalog: 'catalog',
  product: 'product',
  cart: 'cart',
  notFoundWithShopId: 'notFoundWithShopId',
  notFound: 'notFound',
  selectTokenForPay: 'selectTokenForPay',
  createInvoice: 'createInvoice',
  approvePay: 'approvePay',
}
