import React from 'react'
import PropTypes from 'prop-types'
import Portal from '../Portal/Protal'
import './overlaying-popup.scss'

const OverlayingPopup = ({ children, onClose, isOpen }) => {
  if (!isOpen) {
    return null
  }

  return (
    <Portal>
      <div className="overlaying-popup__container" role="dialog">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="overlaying-popup__overlay"
          role="button"
          tabIndex={0}
          onClick={onClose}
        />
        {children}
      </div>
    </Portal>
  )
}

OverlayingPopup.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
}

OverlayingPopup.defaultProps = {
  onClose: () => {},
}

export default OverlayingPopup
