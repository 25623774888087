import Axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import config from './config'
import { baseUrl } from '../const'

const axios = setupCache(Axios, {
  ttl: 60 * 1000,
})

export default ({
  url = '/',
  method = 'GET',
  params = {},
  data = {},
  headers = {},
  isMock = false,
  mockData = {},
}) => {
  const headersCustom = headers
  const regex = new RegExp('^(http|https)://', 'i')
  const newUrl = regex.test(url) ? url : `${baseUrl}${url}`

  if (isMock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData)
      }, 1000)
    })
  }

  return axios({
    url: newUrl,
    method,
    params,
    data,
    headers: headersCustom,
  }).catch((error) => {
    config.errorHandler(error)
    throw error
  })
}
