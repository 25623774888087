import { createSlice } from '@reduxjs/toolkit'

export const locale = createSlice({
  name: 'locale',
  initialState: {
    afterPay: 'Спасибо за покупку!',
    billTitle: 'Оплата в магазине',
    errorPay: 'К сожалению оплата не прошла',
    forBuy: 'Оплатить',
  },
  reducers: {
    setLocale: (state, action) => ({ ...state, ...action.payload }),
  },
})

export const selectLocale = (state) => state.locale

export const { setLocale } = locale.actions
