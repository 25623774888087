/* eslint-disable no-param-reassign */
import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'

export const cartAdapter = createEntityAdapter({
  selectId: (item) => item.id,
})

export const cart = createSlice({
  name: 'cart',
  initialState: cartAdapter.getInitialState({
    totalAmount: 0,
  }),
  reducers: {
    addItem: (state, { payload }) => {
      const { price } = payload
      cartAdapter.addOne(state, payload)

      state.totalAmount += price
    },
    removeItem: (state, { payload }) => {
      const { price, id } = payload

      cartAdapter.removeOne(state, id)
      state.totalAmount -= price
    },
    updateItem: (state, { payload }) => {
      const { id, ...changes } = payload
      cartAdapter.updateOne(state, { id, changes })

      state.totalAmount = state.entities[id].price * changes.count
    },
    setEmpty: (state) => {
      cartAdapter.removeAll(state)

      state.totalAmount = 0
    },
  },
})

const selectSelf = (state) => state

export const {
  addItem, updateItem, removeItem, setEmpty,
} = cart.actions

export const {
  selectById: selectCartById,
  selectIds: selectCartIds,
  selectEntities: selectCartEntities,
  selectAll: selectAllCart,
  selectTotal: selectTotalCart,
} = cartAdapter.getSelectors((state) => state.cart)

export const selectCartEntity = (id) => createSelector(selectSelf, (state) => selectCartById(state, id))
export const selectTotalAmountByCart = (state) => state.cart.totalAmount
