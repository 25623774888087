import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import {
  addItem, updateItem, removeItem, selectCartEntity,
} from '../../../store/slice/cart'
import { selectCatalogEntity } from '../../../store/slice/catalog'
import './card.scss'
import { checkExistImg } from '../../../utils/files'
import { selectAvailableTypesFiat } from '../../../store/slice/pay'

const Card = ({ id }) => {
  const selectedByIdProductFromCart = useSelector(selectCartEntity(id))
  const selectedByIdProductFromCatalog = useSelector(selectCatalogEntity(id))
  const fiatCurrency = useSelector(selectAvailableTypesFiat)
  const navigate = useNavigate()
  const [localCount, setCount] = useState(selectedByIdProductFromCart ? selectedByIdProductFromCart.count : 0)
  const [srcImg, setSrcImg] = useState()
  const dispatch = useDispatch()

  const onClickAddProduct = (event, action = 'plus') => {
    event.preventDefault()
    event.stopPropagation()

    const newCount = action === 'plus' ? localCount + 1 : localCount - 1

    setCount(newCount)

    if (newCount === 0) {
      dispatch(removeItem({ id, price: selectedByIdProductFromCatalog.price }))

      return
    }
    if (localCount > 0) {
      dispatch(updateItem({ id, count: newCount }))
      return
    }

    dispatch(addItem({
      id, title: selectedByIdProductFromCatalog.title, price: selectedByIdProductFromCatalog.price, count: newCount,
    }))
  }

  const onClickTheCard = (e) => {
    e.preventDefault()
    navigate(`product/${id}`)
  }

  const renderBtn = () => {
    if (localCount > 0) {
      return (
        <div className="card__item__btn card__item__btn--with-action">
          <div onClick={(e) => onClickAddProduct(e, 'minus')} className="card__item__btn--with-action__minus">-</div>
          {localCount}
          <div onClick={(e) => onClickAddProduct(e)} className="card__item__btn--with-action__add">+</div>
        </div>
      )
    }
    return (
      <div onClick={(e) => onClickAddProduct(e)} className="card__item__btn">Добавить</div>
    )
  }

  useEffect(() => {
    checkExistImg(selectedByIdProductFromCatalog.images?.attachment?.filepath)
      .then((res) => setSrcImg(res.srcImg))
      .catch(() => setSrcImg(undefined))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedByIdProductFromCatalog.images?.attachment?.filepath])

  useEffect(() => {
    setCount(selectedByIdProductFromCart ? selectedByIdProductFromCart.count : 0)
  }, [selectedByIdProductFromCart, selectedByIdProductFromCatalog.title])

  return (
    <div className="card__container" onClick={onClickTheCard}>
      {srcImg
        ? <img src={srcImg} alt="The product" className="card__item__img" />
        : <div className="card__item__img card__img--plug" />}

      <div className="card__item__text card__item__text__title">{selectedByIdProductFromCatalog.title}</div>

      <div className="card__item__text">
        {selectedByIdProductFromCatalog.price}
        {' '}
        {fiatCurrency?.symbol}
      </div>

      {renderBtn()}
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.number.isRequired,
}

export default Card
