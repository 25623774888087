import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './product.scss'
import DumbMainContainer from '../main-container/DumbMainContainer/DumbMainContainer'
import Content from './Content/Content'

const Product = () => {
  const { productId, shopId } = useParams()
  const navigate = useNavigate()

  return (
    <DumbMainContainer
      isNeedBtnBack
      isHiddenMainButton
      isLoadingBtn={false}
      localeMainButton=""
      onBack={() => navigate(`/${shopId}`)}
      title=""
      onClickMainButton={() => {}}
    >
      <Content productId={productId} />
    </DumbMainContainer>
  )
}

export default Product
