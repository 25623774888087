import * as catalog from './rest/catalog'
import * as cart from './rest/cart'
import * as exchangeRate from './rest/exchange-rate'
import * as coinGecko from './coinGekoApi'

export default {
  catalog,
  cart,
  coinGecko,
  exchangeRate,
}
