import { baseUrl } from '../const'

export const checkExistImg = (url) => {
  const srcImg = `${baseUrl}${url}`

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = srcImg

    if (img.complete) {
      resolve({ srcImg, isExist: true })
    } else {
      img.onload = () => {
        resolve({ srcImg, isExist: true })
      }
      img.onerror = () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ isExist: false })
      }
    }
  })
}
