import { CONST_ROUTER_ID } from '../../const/index'

export const initData = {
  title: '',
  isNeedBtnBack: false,
  isHiddenMainButton: true,
  isLoadingBtn: false,
  onBack: () => {},
  onClickMainButton: () => {},
  localeMainButton: 'Перейти в корзину',
}

const CONST_STATE_PRODUCT = {
  title: '',
  isNeedBtnBack: true,
  isHiddenMainButton: true,
  isLoadingBtn: false,
  onBack: () => {},
  onClickMainButton: () => {},
  localeMainButton: 'Добавить',
}

const CONST_STATE_CART = {
  title: 'Корзина',
  isNeedBtnBack: true,
  isHiddenMainButton: false,
  isLoadingBtn: false,
  onBack: () => {},
  onClickMainButton: () => {},
  localeMainButton: 'Оплатить',
}

export const CONST_STATE_SELECT_TOKEN = {
  title: 'Выберите токен',
  isNeedBtnBack: true,
  isHiddenMainButton: false,
  isLoadingBtn: false,
  onBack: () => {},
  onClickMainButton: () => {},
  localeMainButton: 'Продолжить',
}

/**
 * @param {object} state
 * @param {string} type
 * @param {number} shopId
 * @param {object} localeButtons
 * @param {string} localeButtons.localeMainButton
 * @param {string} localeButtons.localeBackButton
 * @param {boolean} isHandleHiddenMinButton
 * @param {boolean} isHandleLoadingMainBtn
 * @param {func} navigate
 * @param {func} onHandlePay
 * @returns {{
 * isNeedBtnBack: boolean,
 * onBack: func,
 * isLoadingBtn: boolean,
 * isHiddenMainButton: boolean,
 * onClickMainButton: func,
 * title: string,
 * localeMainButton: string
 * }}
 */
export const reducerForMainContainer = (
  state,
  {
    type,
    shopId,
    localeButtons = {},
    isHandleHiddenMinButton,
    isHandleLoadingMainBtn,
    navigate,
    onHandlePay,
  },
) => {
  switch (type) {
    case CONST_ROUTER_ID.catalog:
      return {
        ...initData,
        title: '',
        isLoadingBtn: isHandleLoadingMainBtn,
        isHiddenMainButton: isHandleHiddenMinButton,
        localeMainButton: localeButtons.mainButton,
        onClickMainButton: () => navigate(`/${shopId}/cart`),
      }
    case CONST_ROUTER_ID.product:
      return {
        ...CONST_STATE_PRODUCT,
        isLoadingBtn: isHandleLoadingMainBtn,
        localeMainButton: localeButtons.addProductButton,
        onBack: () => navigate(`/${shopId}`),
      }
    case CONST_ROUTER_ID.cart:
      return {
        ...CONST_STATE_CART,
        isHiddenMainButton: false,
        isLoadingBtn: isHandleLoadingMainBtn,
        localeMainButton: 'Оплатить', // localeButtons.mainButton,
        onBack: () => navigate(`/${shopId}`),
        onClickMainButton: onHandlePay,
      }
    default:
      return initData
  }
}
