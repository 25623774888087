import makeRequest from '../makeRequest'

/**
 * Get url for payment method
 * @param {number} shopId
 * @param {object} products
 * @param {number} chatId
 * @param {string} paymentType
 * @param {string} tokenId
 * @returns {Promise<unknown>|Promise<AxiosResponse<any>>|*}
 */
export const getPayUrl = ({
  shopId, products, chatId, paymentType, tokenId,
}) => makeRequest({
  url: `/api/shop/${shopId}/order`,
  method: 'POST',
  data: {
    products,
    chatId,
    paymentType,
    tokenId,
  },
})
