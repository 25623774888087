import React, {
  createContext, useContext, useState,
} from 'react'
import Web3 from 'web3'

const Web3Context = createContext()

const useWeb3 = () => {
  const context = useContext(Web3Context)
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider')
  }
  return context
}

const Web3Provider = (props) => {
  const initWeb3Context = {
    account: undefined,
    active: false,
    chainId: undefined,
    error: undefined,
    library: undefined,
  }
  const [web3Context, setWeb3Context] = useState(initWeb3Context)

  const setWalletConnectEvents = (provider) => {
    provider.on('chainChanged', (chainId) => setWeb3Context((prevState) => ({ ...prevState, chainId })))
    provider.on('accountsChanged', (data) => setWeb3Context((prevState) => ({ ...prevState, account: data[0] })))
    provider.on('disconnect', () => console.log('disconnect'))
  }

  const value = {
    ...web3Context,
    activate: async ({
      provider,
      onConnect = () => {},
    }) => {
      try {
        const address = await onConnect() || []

        const newLibrary = new Web3(provider)
        const chainId = await newLibrary.eth.getChainId()
        const account = (await newLibrary.eth.getAccounts())[0]

        setWalletConnectEvents(provider)

        setWeb3Context({
          ...initWeb3Context,
          account,
          chainId,
          library: newLibrary,
          active: true,
        })

        return address[0]
      } catch (e) {
        setWeb3Context({
          ...initWeb3Context,
          error: `Activation error: ${e}`,
        })
        console.error(`Activation error: ${e.message}`)

        throw e
      }
    },
  }

  return <Web3Context.Provider value={value} {...props} />
}

export { Web3Provider, useWeb3 }
