import React, { memo, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Tooltip } from 'components/Tooltip'
import infoIcon from 'icons/icon-info.svg'
import useGetFee from 'hooks/useGetFee'
import './fee.scss'
import PropTypes from 'prop-types'

const CustomSkeleton = () => (
  <Skeleton inline baseColor="var(--tg-theme-hint-color)" highlightColor="var(--tg-theme-text-color)" />
)
const Fee = ({ setFeeAmount }) => {
  const { fee, isLoading } = useGetFee()

  useEffect(() => {
    setFeeAmount(fee.bnb)
  }, [fee.bnb, setFeeAmount])

  return (
    <div className="select-token-for-pay__commission">
      {isLoading ? (
        <CustomSkeleton />
      ) : (
        <div className="select-token-for-pay__commission__text">
          Плата за газ:
          {' '}
          {fee.bnb}
          {' '}
          BNB
          ($
          {fee.usdt}
          )
          {' '}
          <div className="select-token-for-pay__commission__tooltip">
            <Tooltip text="Примерная суммарная плата за газ для предоставления доступа к токену и проведения транзакции">
              <img className="select-token-for-pay__commission__tooltip-icon" src={infoIcon} alt="info" />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  )
}

Fee.propTypes = {
  setFeeAmount: PropTypes.func.isRequired,
}

export default memo(Fee)
