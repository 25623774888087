import { createBrowserRouter } from 'react-router-dom'
import React from 'react'
import Catalog from './pages/Catalog/Catalog'
import Product from './pages/Product/Product'
import Cart from './pages/Cart/Cart'
import NotFound from './pages/NotFound/NotFound'
import MainContainer from './pages/main-container/MainContainer'
import { CONST_ROUTER_ID } from './const'
import SelectTokenForPay from './pages/SelectTokenForPay/SelectTokenForPay'

const CatalogElement = <MainContainer><Catalog /></MainContainer>
const ProductElement = <Product />
const CartElement = <MainContainer><Cart /></MainContainer>
const NotFoundElement = <MainContainer><NotFound /></MainContainer>
const SelectTokenForPayElement = <SelectTokenForPay />

const router = createBrowserRouter(
  [
    {
      id: CONST_ROUTER_ID.main,
      path: '/:shopId',
      children: [
        {
          id: CONST_ROUTER_ID.catalog,
          element: CatalogElement,
          index: true,
        },
        {
          id: CONST_ROUTER_ID.product,
          element: ProductElement,
          path: 'product/:productId',
        },
        {
          path: 'cart',
          children: [
            {
              id: CONST_ROUTER_ID.cart,
              element: CartElement,
              index: true,
            },
            {
              path: 'select-token-for-pay',
              id: CONST_ROUTER_ID.selectTokenForPay,
              element: SelectTokenForPayElement,
            },
          ],
        },
        {
          id: CONST_ROUTER_ID.notFoundWithShopId,
          path: '*',
          element: NotFoundElement,
        },
      ],
    },
    {
      id: CONST_ROUTER_ID.notFound,
      path: '*',
      element: NotFoundElement,
    },
  ],
)

export default router
