import { LIST_WEB3_PROVIDER } from './web3/LIST_WEB3_PROVIDER'
import {
  OUR_CONTRACT_ADDRESS, USDT_CONTRACT_ADDRESS, BITCOIN_CONTRACT_ADDRESS, BUSD_CONTRACT_ADDRESS, BNB_CONTRACT_ADDRESS,
  addressToAbi,
} from './web3/adress'
import { CONST_ROUTER_ID } from './router/router'
import { isDevelopment } from './flags'

export const baseUrl = process.env.REACT_APP_API

export const tgWebApp = (window.Telegram) ? window.Telegram.WebApp : { initDataUnsafe: '' }

export const idForCalculateCommission = isDevelopment ? 88 : 142

export const WALLETCONNECT_NAME = process.env.REACT_APP_WALLETCONNECT_NAME
export const WALLETCONNECT_ID = process.env.REACT_APP_WALLETCONNECT_ID

export {
  OUR_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS,
  BITCOIN_CONTRACT_ADDRESS,
  BUSD_CONTRACT_ADDRESS,
  BNB_CONTRACT_ADDRESS,
  LIST_WEB3_PROVIDER,
  CONST_ROUTER_ID,
  isDevelopment,
  addressToAbi,
}
