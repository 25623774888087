import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectLocale } from '../../../store/slice/locale'
import iconSuccess from '../../../icons/success.svg'
import MainPopupWithoutHeaderAction
from '../../../components/MainPopupWithoutHeaderAction/MainPopupWithoutHeaderAction'

const SuccessPay = ({ isOpen, onClosePopup }) => {
  const sliceLocale = useSelector(selectLocale)

  return (
    <MainPopupWithoutHeaderAction
      title="Pay"
      isOpen={isOpen}
      onClose={onClosePopup}
      primaryAction={{
        text: 'Вернуться на главную',
        onClick: onClosePopup,
      }}
    >
      <div className="modal-state__container">
        <div className="modal-state__message">
          {sliceLocale.afterPay}
        </div>

        <div className="modal-state__state">
          <img className="modal-state__state--icon" src={iconSuccess} alt="success" />
        </div>
      </div>
    </MainPopupWithoutHeaderAction>
  )
}

SuccessPay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
}

export default SuccessPay
