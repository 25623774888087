import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { cart } from './slice/cart'
import { catalog } from './slice/catalog'
import { locale } from './slice/locale'
import { pay } from './slice/pay'

const middleware = getDefaultMiddleware({
  immutableCheck: true,
  serializableCheck: false,
  thunk: true,
})

export const store = configureStore({
  reducer: {
    cart: cart.reducer,
    catalog: catalog.reducer,
    locale: locale.reducer,
    pay: pay.reducer,
  },
  middleware,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
})
