import React from 'react'
import './main-container.scss'
import PropTypes from 'prop-types'
import ArrowLeft from '../../../icons/arrow-left.svg'
import Loader from '../../../components/Loader/Loader'

const DumbMainContainer = ({
  children,
  title,
  isNeedBtnBack,
  isHiddenMainButton,
  isLoadingBtn,
  onBack,
  onClickMainButton,
  localeMainButton,
}) => {
  const handleOnClick = () => {
    if (onClickMainButton && !isLoadingBtn) {
      onClickMainButton()
    }
  }

  return (
    <div className="main-wrapper">
      <div className="main-container">
        <header className="main-container__header">
          {isNeedBtnBack && <img onClick={onBack} className="main-container__header__back" src={ArrowLeft} alt="back" />}
          {title}
        </header>

        <div className="main-container__container">{children}</div>

        {!isHiddenMainButton && (
          <div onClick={handleOnClick} className="main-container__pay">
            {isLoadingBtn
              ? <div className="main-container__pay--loading"><Loader size="small" /></div>
              : localeMainButton}
          </div>
        )}
      </div>
    </div>
  )
}

DumbMainContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  isNeedBtnBack: PropTypes.bool,
  isHiddenMainButton: PropTypes.bool,
  isLoadingBtn: PropTypes.bool,
  onClickMainButton: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  localeMainButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
}

DumbMainContainer.defaultProps = {
  isNeedBtnBack: false,
  isHiddenMainButton: false,
  isLoadingBtn: false,
  onBack: () => {},
}

export default DumbMainContainer
