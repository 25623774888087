import React, { useEffect, useState, memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import BN from 'bignumber.js'
import PropTypes from 'prop-types'
import { selectTotalAmountByCart } from '../../../../../store/slice/cart'
import api from '../../../../../api'
import { selectAvailableTypesFiat } from '../../../../../store/slice/pay'
import MainPopupWithoutHeaderAction
from '../../../../../components/MainPopupWithoutHeaderAction/MainPopupWithoutHeaderAction'

const CustomSkeleton = () => (
  <Skeleton inline baseColor="var(--tg-theme-hint-color)" highlightColor="var(--tg-theme-text-color)" />
)

const FinalCost = ({ selectedToken = { id: undefined }, setTotalAmountForPay, fee }) => {
  const cartAmount = useSelector(selectTotalAmountByCart)
  const availableFiat = useSelector(selectAvailableTypesFiat)

  const [cost, setCost] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    (async () => {
      if (!selectedToken.id || !availableFiat.id || !cartAmount) return

      try {
        setIsLoading(true)

        const price = await api.exchangeRate.getExchangeRate({ token: selectedToken.id, fiat: availableFiat.id })
        const totalAmount = new BN(cartAmount).dividedBy(new BN(price.data)).toString()

        setCost(totalAmount)
      } catch (e) {
        console.error('Calculate total amount for pay: ', e)

        setIsOpenModal(true)
        setCost(0)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [availableFiat.id, cartAmount, selectedToken.id])

  useEffect(() => {
    setTotalAmountForPay(cost)
  }, [cost, setTotalAmountForPay])

  return (
    <div className="select-token-for-pay__total-amount">
      <MainPopupWithoutHeaderAction
        isOpen={isOpenModal}
        title="Error"
        primaryAction={{
          text: 'Вернуться в приложение',
          onClick: () => setIsOpenModal(false),
        }}
      >
        Что-то пошло не так. Пожалуйста, попробуйте еще раз
      </MainPopupWithoutHeaderAction>

      {isLoading ? (
        <CustomSkeleton />
      ) : (
        <>
          Итого:
          {' '}
          {cost}
          {' '}
          {selectedToken?.name?.toUpperCase()}
          {' '}
          {'+'}
          {' '}
          {fee > 0 && `${fee} BNB`}
        </>
      )}
    </div>
  )
}

FinalCost.propTypes = {
  selectedToken: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  setTotalAmountForPay: PropTypes.func.isRequired,
  fee: PropTypes.number.isRequired,
}

export default memo(FinalCost)
