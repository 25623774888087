export const ACTION_TYPE_MODAL = {
  APPROVE_LOADING: 'APPROVE_LOADING',
  APPROVE_SUCCESS: 'APPROVE_SUCCESS',
  PAY_LOADING: 'PAY_LOADING',
  PAY_SUCCESS: 'PAY_SUCCESS',
  CLOSE: 'CLOSE',
  ERROR: 'ERROR',
}

export const CONST_STATES = {
  loading: 'loading',
  success: 'success',
  error: 'error',
}

export const initialState = {
  message: '',
  primaryAction: {
    text: '',
    onClick: () => {},
  },
  isOpen: false,
  title: '',
}

export const reducerModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_MODAL.APPROVE_LOADING:
      return {
        ...state,
        primaryAction: undefined,
        isOpen: true,
        title: 'Approve',
        message: 'Пожалуйста, перейдите в криптокошелек и подтвердите доступ',
        state: CONST_STATES.loading,
      }
    case ACTION_TYPE_MODAL.APPROVE_SUCCESS:
      return {
        ...state,
        primaryAction: undefined,
        isOpen: true,
        title: 'Approve',
        message: 'Доступ к токену в криптокошельке получен!',
        state: CONST_STATES.success,
      }
    case ACTION_TYPE_MODAL.PAY_LOADING:
      return {
        ...state,
        primaryAction: undefined,
        isOpen: true,
        title: 'Pay',
        message: 'Пожалуйста, перейдите в криптокошелек и подтвердите транзакцию',
        state: CONST_STATES.loading,
      }
    case ACTION_TYPE_MODAL.PAY_SUCCESS:
      return {
        ...state,
        isOpen: true,
        primaryAction: {
          text: 'Вернуться на главную',
          onClick: action.payload.onClick,
        },
        message: 'Оплата прошла успешно!',
        title: 'Pay',
        state: CONST_STATES.success,
      }
    case ACTION_TYPE_MODAL.CLOSE:
      return {
        ...state,
        isOpen: false,
        primaryAction: undefined,
      }
    case ACTION_TYPE_MODAL.ERROR:
      return {
        ...state,
        primaryAction: {
          text: 'Вернуться в приложение',
          onClick: action.payload.onClick,
        },
        isOpen: true,
        state: CONST_STATES.error,
        title: 'Error',
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз',
      }
    default:
      return state
  }
}
