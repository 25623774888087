import React, { useEffect } from 'react'
import { useAccount, useDisconnect } from 'wagmi'

function Disconnect() {
  const { status } = useDisconnect()
  const { isConnected } = useAccount()

  useEffect(() => {
    if (status === 'success' || (!isConnected && status === 'idle' && localStorage.getItem('wagmi.cache'))) {
      localStorage.clear()
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }
  }, [isConnected, status])

  return (<></>)
}

export default Disconnect
