import React, {
  useCallback, useEffect, useMemo, useReducer, useState, memo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BN from 'bignumber.js'
import {
  erc20ABI, useAccount, useContract, useContractRead, useContractWrite, usePrepareContractWrite, useSigner,
} from 'wagmi'
import { selectAllCart, setEmpty } from 'store/slice/cart'
import { contractAbi } from 'abi'
import {
  BITCOIN_CONTRACT_ADDRESS, BNB_CONTRACT_ADDRESS,
  BUSD_CONTRACT_ADDRESS,
  OUR_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS,
} from 'const'
import DumbMainContainer from 'pages/main-container/DumbMainContainer/DumbMainContainer'
import MainPopupWithoutHeaderAction
from 'components/MainPopupWithoutHeaderAction/MainPopupWithoutHeaderAction'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import iconSuccess from 'icons/success.svg'
import iconWarning from 'icons/warning.svg'
import { onSendCart } from '../../service'
import ListTokens from './ListTokens/ListTokens'
import Fee from './Fee/Fee'
import FinalCost from './FinalCost/FinalCost'
import {
  ACTION_TYPE_MODAL, initialState, reducerModal, CONST_STATES,
} from './reducer'

const CONST_TOKENS_ADDRESS = {
  usdt: USDT_CONTRACT_ADDRESS,
  busd: BUSD_CONTRACT_ADDRESS,
  btcb: BITCOIN_CONTRACT_ADDRESS,
  bnb: BNB_CONTRACT_ADDRESS,
}

const Pay = ({
  shopId, onSetIsLoadingMainBtn, navigate, isLoadingMainBtn,
}) => {
  const [invoiceId, setInvoiceId] = useState()
  const [tokenAddress, setTokenAddress] = useState()
  const [selectedToken, setSelectedToken] = useState()
  const [totalAmountForPay, setTotalAmountForPay] = useState(0)
  const [feeAmount, setFeeAmount] = useState(0)
  const [isNeedApprove, setIsNeedApprove] = useState(false)

  const [settingsModal, dispatchSettingsModal] = useReducer(reducerModal, initialState)

  const sum = useMemo(() => {
    const bnDecimals = new BN(10).pow(18)

    return new BN(totalAmountForPay).multipliedBy(bnDecimals).toNumber().toFixed()
  }, [totalAmountForPay])

  const dispatch = useDispatch()
  const { address } = useAccount()
  const allCart = useSelector(selectAllCart)
  const { data: signer } = useSigner()
  const approveConfig = usePrepareContractWrite({
    address: tokenAddress,
    abi: erc20ABI,
    args: [OUR_CONTRACT_ADDRESS, sum],
    functionName: 'approve',
  })
  const contractApprove = useContractWrite(approveConfig.config)
  const contractAllowance = useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [address, OUR_CONTRACT_ADDRESS],
  })
  const contractPay = useContract({
    address: OUR_CONTRACT_ADDRESS,
    abi: contractAbi,
    signerOrProvider: signer,
  })

  const closeModal = useCallback(() => {
    dispatchSettingsModal({ type: ACTION_TYPE_MODAL.CLOSE })
  }, [])

  const onPaySuccess = useCallback(() => {
    dispatch(setEmpty())
    navigate(`/${shopId}`)
  }, [dispatch, navigate, shopId])

  const onApprove = useCallback(async ({
    approve, allowance, _sum, _closeModal,
  }) => {
    if (new BN(allowance).gte(new BN(_sum))) {
      return true
    }

    try {
      await (await approve({ recklesslySetUnpreparedArgs: [OUR_CONTRACT_ADDRESS, _sum] }))?.wait()

      return true
    } catch (e) {
      dispatchSettingsModal({ type: ACTION_TYPE_MODAL.ERROR, payload: { onClick: _closeModal } })
      console.error('tx', e)

      return false
    }
  }, [])

  const onPay = useCallback(({ _selectedToken, id, _closeModal }) => {
    (async () => {
      if (_selectedToken.name.toLowerCase() === 'bnb') {
        dispatchSettingsModal({ type: ACTION_TYPE_MODAL.PAY_LOADING })
        try {
          await contractPay.payByInternalToken(id, { gasLimit: 500000 })

          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.PAY_SUCCESS, payload: { onClick: onPaySuccess } })
        } catch (e) {
          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.ERROR, payload: { onClick: _closeModal } })
          console.error('Pay BNB: ', e)
        }

        return
      }

      setTimeout(async () => {
        try {
          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.PAY_LOADING })

          await contractPay.pay(id, { gasLimit: 500000 })

          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.PAY_SUCCESS, payload: { onClick: onPaySuccess } })
        } catch (e) {
          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.ERROR, payload: { onClick: _closeModal } })
          console.error('Pay: ', e)
        }
      }, 3000)
    })()
  }, [contractPay, onPaySuccess])

  const createInvoice = useCallback(() => {
    (async () => {
      if (!selectedToken) {
        return
      }

      onSetIsLoadingMainBtn(true)

      try {
        let id = invoiceId

        if (!id) {
          id = await onSendCart({ shopId, tokenId: selectedToken.id, allCart })
          setInvoiceId(+id)
        }

        setIsNeedApprove(true)
      } catch (e) {
        console.error('createInvoice: ', e)

        dispatchSettingsModal({ type: ACTION_TYPE_MODAL.ERROR, payload: { onClick: closeModal } })

        onSetIsLoadingMainBtn(false)
      }
    })()
  }, [allCart, closeModal, invoiceId, onSetIsLoadingMainBtn, selectedToken, shopId])

  const renderContentPopup = useMemo(() => {
    const { message } = settingsModal
    let state = ''

    switch (settingsModal.state) {
      case CONST_STATES.loading:
        state = <Loader />
        break
      case CONST_STATES.success:
        state = <img className="modal-state__state--icon" src={iconSuccess} alt="success" />
        break
      case CONST_STATES.error:
        state = <img className="modal-state__state--icon" src={iconWarning} alt="warning" />
        break
      default:
        break
    }

    return (
      <div className="modal-state__container">
        <div className="modal-state__message">
          {message}
        </div>

        <div className="modal-state__state">
          {state}
        </div>
      </div>
    )
  }, [settingsModal])

  useEffect(() => {
    if (selectedToken && selectedToken.name) {
      setInvoiceId(null)
      setTokenAddress(CONST_TOKENS_ADDRESS[selectedToken.name.toLowerCase()])
    }
  }, [selectedToken])

  // Approve
  useEffect(() => {
    (async () => {
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
      const isBnb = selectedToken.name.toLowerCase() === 'bnb'

      if (!invoiceId || (!contractApprove.writeAsync && !isBnb) || !contractApprove.isIdle || !sum) {
        return
      }
      if (!isNeedApprove) {
        return
      }
      setIsNeedApprove(false)

      // This is a hack to prevent the error always rendering
      // So setIsNeedApprove does not have time to set the value
      await sleep(100)

      if (isBnb) {
        try {
          await onPay({ _selectedToken: selectedToken, id: invoiceId, _closeModal: closeModal })
        } catch (e) {
          console.error('Pay bnb: ', e)
          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.ERROR, payload: { onClick: closeModal } })
        } finally {
          onSetIsLoadingMainBtn(false)
        }

        return
      }

      try {
        dispatchSettingsModal({ type: ACTION_TYPE_MODAL.APPROVE_LOADING })
        const approve = await onApprove({
          approve: contractApprove.writeAsync,
          _sum: sum,
          allowance: contractAllowance.data?.toString(),
          _closeModal: closeModal,
        })

        if (approve) {
          dispatchSettingsModal({ type: ACTION_TYPE_MODAL.APPROVE_SUCCESS })

          await onPay({ _selectedToken: selectedToken, id: invoiceId, _closeModal: closeModal })
        }
      } catch (e) {
        console.error('useEffect: ', e)
        dispatchSettingsModal({ type: ACTION_TYPE_MODAL.ERROR, payload: { onClick: closeModal } })
      } finally {
        onSetIsLoadingMainBtn(false)
      }
    })()
  }, [
    onApprove,
    invoiceId,
    sum,
    contractAllowance.data,
    contractApprove.writeAsync,
    contractApprove.isIdle,
    isNeedApprove,
    selectedToken,
    onPay,
    dispatchSettingsModal,
    closeModal,
    onSetIsLoadingMainBtn,
    contractApprove,
  ])

  return (
    <DumbMainContainer
      title="Выберите токен"
      isNeedBtnBack
      isHiddenMainButton={false}
      onBack={() => navigate(`/${shopId}/cart`)}
      onClickMainButton={createInvoice}
      localeMainButton="Продолжить"
      isLoadingBtn={isLoadingMainBtn}
    >
      <MainPopupWithoutHeaderAction {...settingsModal}>
        {renderContentPopup}
      </MainPopupWithoutHeaderAction>

      <div className="select-token-for-pay__container">
        <ListTokens onSetSelectedToken={setSelectedToken} selectedToken={selectedToken} />

        <div className="select-token-for-pay__total-amount-and-commission">
          <Fee setFeeAmount={setFeeAmount} />

          <FinalCost fee={feeAmount} selectedToken={selectedToken} setTotalAmountForPay={setTotalAmountForPay} />
        </div>
      </div>
    </DumbMainContainer>
  )
}

Pay.propTypes = {
  shopId: PropTypes.number.isRequired,
  onSetIsLoadingMainBtn: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  isLoadingMainBtn: PropTypes.bool.isRequired,
}

export default memo(Pay)
