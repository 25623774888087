export const contractAbi = [
  {
    inputs: [{
      internalType: 'uint16',
      name: '_fee',
      type: 'uint16',
    }, { internalType: 'address', name: '_treasury', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'newOwner',
      type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'uint256', name: 'invoice_id', type: 'uint256',
    }],
    name: 'canceled',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'uint256', name: 'invoice_id', type: 'uint256',
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256',
    }, {
      indexed: true, internalType: 'address', name: 'seller', type: 'address',
    }],
    name: 'createInvoice',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'uint256', name: 'invoice_id', type: 'uint256',
    }, {
      indexed: false,
      internalType: 'address',
      name: 'payer',
      type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'timestamp', type: 'uint256',
    }],
    name: 'paid',
    type: 'event',
  }, {
    inputs: [{ internalType: 'uint256', name: '_invoice_id', type: 'uint256' }],
    name: 'cancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }, {
      internalType: 'address',
      name: '_seller',
      type: 'address',
    }, { internalType: 'uint256', name: '_untilTime', type: 'uint256' }, {
      internalType: 'address',
      name: '_token',
      type: 'address',
    }],
    name: 'create_invoice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'fee',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'invoice_id',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'invoices',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }, {
      internalType: 'address',
      name: 'seller',
      type: 'address',
    }, { internalType: 'uint8', name: 'state', type: 'uint8' }, {
      internalType: 'uint256',
      name: 'untilTime',
      type: 'uint256',
    }, { internalType: 'address', name: 'token', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_invoice_id', type: 'uint256' }],
    name: 'pay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_invoice_id', type: 'uint256' }],
    name: 'payByInternalToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint16', name: '_fee', type: 'uint16' }],
    name: 'set_fee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_treasury', type: 'address' }],
    name: 'set_treasury',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'treasury',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }]

export const usdtAbi = [
  {
    inputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'owner', type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'spender',
      type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'Approval',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'newOwner',
      type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'from', type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
    }],
    name: 'Transfer',
    type: 'event',
  }, {
    constant: true,
    inputs: [],
    name: '_decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: '_name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: '_symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, {
      internalType: 'address',
      name: 'spender',
      type: 'address',
    }],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256',
    }],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, {
      internalType: 'uint256',
      name: 'subtractedValue',
      type: 'uint256',
    }],
    name: 'decreaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'getOwner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, {
      internalType: 'uint256',
      name: 'addedValue',
      type: 'uint256',
    }],
    name: 'increaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'mint',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'address', name: 'recipient', type: 'address' }, {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256',
    }],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }, {
      internalType: 'address',
      name: 'recipient',
      type: 'address',
    }, { internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }]

export const ERC20_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'address',
        name: '_owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
    ],
    name: 'allowance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'getOwner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'transferFrom',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

export const BUSD_ABI = [
  {
    constant: false,
    inputs: [],
    name: 'disregardProposeOwner',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_spender', type: 'address' }, { name: '_value', type: 'uint256' }],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'assetProtectionRole',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: 'r', type: 'bytes32[]' }, { name: 's', type: 'bytes32[]' }, { name: 'v', type: 'uint8[]' }, {
      name: 'to',
      type: 'address[]',
    }, { name: 'value', type: 'uint256[]' }, { name: 'fee', type: 'uint256[]' }, {
      name: 'seq',
      type: 'uint256[]',
    }, { name: 'deadline', type: 'uint256[]' }],
    name: 'betaDelegatedTransferBatch',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: 'sig', type: 'bytes' }, { name: 'to', type: 'address' }, { name: 'value', type: 'uint256' }, {
      name: 'fee',
      type: 'uint256',
    }, { name: 'seq', type: 'uint256' }, { name: 'deadline', type: 'uint256' }],
    name: 'betaDelegatedTransfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_from', type: 'address' }, { name: '_to', type: 'address' }, { name: '_value', type: 'uint256' }],
    name: 'transferFrom',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'initializeDomainSeparator',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'unpause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'unfreeze',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'claimOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_newSupplyController', type: 'address' }],
    name: 'setSupplyController',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'initialize',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'pause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'getOwner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ name: 'target', type: 'address' }],
    name: 'nextSeqOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_newAssetProtectionRole', type: 'address' }],
    name: 'setAssetProtectionRole',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'freeze',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_newWhitelister', type: 'address' }],
    name: 'setBetaDelegateWhitelister',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_value', type: 'uint256' }],
    name: 'decreaseSupply',
    outputs: [{ name: 'success', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'isWhitelistedBetaDelegate',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_to', type: 'address' }, { name: '_value', type: 'uint256' }],
    name: 'transfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'whitelistBetaDelegate',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_proposedOwner', type: 'address' }],
    name: 'proposeOwner',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_value', type: 'uint256' }],
    name: 'increaseSupply',
    outputs: [{ name: 'success', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'betaDelegateWhitelister',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'proposedOwner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'unwhitelistBetaDelegate',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }, { name: '_spender', type: 'address' }],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'wipeFrozenAddress',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'EIP712_DOMAIN_HASH',
    outputs: [{ name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [{ name: '_addr', type: 'address' }],
    name: 'isFrozen',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: true,
    inputs: [],
    name: 'supplyController',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  }, {
    constant: false,
    inputs: [],
    name: 'reclaimBUSD',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [], payable: false, stateMutability: 'nonpayable', type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'from', type: 'address' }, {
      indexed: true,
      name: 'to',
      type: 'address',
    }, { indexed: false, name: 'value', type: 'uint256' }],
    name: 'Transfer',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'owner', type: 'address' }, {
      indexed: true,
      name: 'spender',
      type: 'address',
    }, { indexed: false, name: 'value', type: 'uint256' }],
    name: 'Approval',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'currentOwner', type: 'address' }, {
      indexed: true,
      name: 'proposedOwner',
      type: 'address',
    }],
    name: 'OwnershipTransferProposed',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'oldProposedOwner', type: 'address' }],
    name: 'OwnershipTransferDisregarded',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'oldOwner', type: 'address' }, { indexed: true, name: 'newOwner', type: 'address' }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false, inputs: [], name: 'Pause', type: 'event',
  }, {
    anonymous: false, inputs: [], name: 'Unpause', type: 'event',
  }, {
    anonymous: false, inputs: [{ indexed: true, name: 'addr', type: 'address' }], name: 'AddressFrozen', type: 'event',
  }, {
    anonymous: false, inputs: [{ indexed: true, name: 'addr', type: 'address' }], name: 'AddressUnfrozen', type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'addr', type: 'address' }],
    name: 'FrozenAddressWiped',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'oldAssetProtectionRole', type: 'address' }, {
      indexed: true,
      name: 'newAssetProtectionRole',
      type: 'address',
    }],
    name: 'AssetProtectionRoleSet',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'to', type: 'address' }, { indexed: false, name: 'value', type: 'uint256' }],
    name: 'SupplyIncreased',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'from', type: 'address' }, { indexed: false, name: 'value', type: 'uint256' }],
    name: 'SupplyDecreased',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'oldSupplyController', type: 'address' }, {
      indexed: true,
      name: 'newSupplyController',
      type: 'address',
    }],
    name: 'SupplyControllerSet',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'from', type: 'address' }, {
      indexed: true,
      name: 'to',
      type: 'address',
    }, { indexed: false, name: 'value', type: 'uint256' }, { indexed: false, name: 'seq', type: 'uint256' }, {
      indexed: false,
      name: 'fee',
      type: 'uint256',
    }],
    name: 'BetaDelegatedTransfer',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'oldWhitelister', type: 'address' }, {
      indexed: true,
      name: 'newWhitelister',
      type: 'address',
    }],
    name: 'BetaDelegateWhitelisterSet',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'newDelegate', type: 'address' }],
    name: 'BetaDelegateWhitelisted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{ indexed: true, name: 'oldDelegate', type: 'address' }],
    name: 'BetaDelegateUnwhitelisted',
    type: 'event',
  }]

export const BNB_ABI = [
  {
    inputs: [{
      internalType: 'address',
      name: '_factory',
      type: 'address',
    }, { internalType: 'address', name: '_WETH', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    inputs: [],
    name: 'WETH',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'tokenA', type: 'address' }, {
      internalType: 'address',
      name: 'tokenB',
      type: 'address',
    }, { internalType: 'uint256', name: 'amountADesired', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountBDesired',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountAMin', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountBMin',
      type: 'uint256',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'addLiquidity',
    outputs: [{ internalType: 'uint256', name: 'amountA', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountB',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'liquidity', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }, {
      internalType: 'uint256',
      name: 'amountTokenDesired',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETHMin',
      type: 'uint256',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'addLiquidityETH',
    outputs: [{ internalType: 'uint256', name: 'amountToken', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETH',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'liquidity', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [],
    name: 'factory',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'reserveIn',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'reserveOut', type: 'uint256' }],
    name: 'getAmountIn',
    outputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'reserveIn',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'reserveOut', type: 'uint256' }],
    name: 'getAmountOut',
    outputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }, {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    }],
    name: 'getAmountsIn',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }, {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    }],
    name: 'getAmountsOut',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountA', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'reserveA',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'reserveB', type: 'uint256' }],
    name: 'quote',
    outputs: [{ internalType: 'uint256', name: 'amountB', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'tokenA', type: 'address' }, {
      internalType: 'address',
      name: 'tokenB',
      type: 'address',
    }, { internalType: 'uint256', name: 'liquidity', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountBMin', type: 'uint256' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'removeLiquidity',
    outputs: [{ internalType: 'uint256', name: 'amountA', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountB',
      type: 'uint256',
    }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }, {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETHMin',
      type: 'uint256',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'removeLiquidityETH',
    outputs: [{ internalType: 'uint256', name: 'amountToken', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETH',
      type: 'uint256',
    }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }, {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETHMin',
      type: 'uint256',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
    outputs: [{ internalType: 'uint256', name: 'amountETH', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }, {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETHMin',
      type: 'uint256',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }, { internalType: 'bool', name: 'approveMax', type: 'bool' }, {
      internalType: 'uint8',
      name: 'v',
      type: 'uint8',
    }, { internalType: 'bytes32', name: 'r', type: 'bytes32' }, {
      internalType: 'bytes32',
      name: 's',
      type: 'bytes32',
    }],
    name: 'removeLiquidityETHWithPermit',
    outputs: [{ internalType: 'uint256', name: 'amountToken', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETH',
      type: 'uint256',
    }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }, {
      internalType: 'uint256',
      name: 'liquidity',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountETHMin',
      type: 'uint256',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }, { internalType: 'bool', name: 'approveMax', type: 'bool' }, {
      internalType: 'uint8',
      name: 'v',
      type: 'uint8',
    }, { internalType: 'bytes32', name: 'r', type: 'bytes32' }, {
      internalType: 'bytes32',
      name: 's',
      type: 'bytes32',
    }],
    name: 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens',
    outputs: [{ internalType: 'uint256', name: 'amountETH', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'tokenA', type: 'address' }, {
      internalType: 'address',
      name: 'tokenB',
      type: 'address',
    }, { internalType: 'uint256', name: 'liquidity', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountAMin',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'amountBMin', type: 'uint256' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }, {
      internalType: 'bool',
      name: 'approveMax',
      type: 'bool',
    }, { internalType: 'uint8', name: 'v', type: 'uint8' }, {
      internalType: 'bytes32',
      name: 'r',
      type: 'bytes32',
    }, { internalType: 'bytes32', name: 's', type: 'bytes32' }],
    name: 'removeLiquidityWithPermit',
    outputs: [{ internalType: 'uint256', name: 'amountA', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountB',
      type: 'uint256',
    }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }, {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'swapETHForExactTokens',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOutMin', type: 'uint256' }, {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'swapExactETHForTokens',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOutMin', type: 'uint256' }, {
      internalType: 'address[]',
      name: 'path',
      type: 'address[]',
    }, { internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256',
      name: 'deadline',
      type: 'uint256',
    }],
    name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    }, { internalType: 'address[]', name: 'path', type: 'address[]' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'swapExactTokensForETH',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    }, { internalType: 'address[]', name: 'path', type: 'address[]' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    }, { internalType: 'address[]', name: 'path', type: 'address[]' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'swapExactTokensForTokens',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountOutMin',
      type: 'uint256',
    }, { internalType: 'address[]', name: 'path', type: 'address[]' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountInMax',
      type: 'uint256',
    }, { internalType: 'address[]', name: 'path', type: 'address[]' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'swapTokensForExactETH',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'amountOut', type: 'uint256' }, {
      internalType: 'uint256',
      name: 'amountInMax',
      type: 'uint256',
    }, { internalType: 'address[]', name: 'path', type: 'address[]' }, {
      internalType: 'address',
      name: 'to',
      type: 'address',
    }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }],
    name: 'swapTokensForExactTokens',
    outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, { stateMutability: 'payable', type: 'receive' }]
