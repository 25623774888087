import api from '../../../api'
import { tgWebApp } from '../../../const'
import { parserSendCartCrypto } from '../../../service'

export const onSendCart = async ({ shopId, allCart, tokenId }) => {
  const result = await api.cart.getPayUrl({
    shopId,
    chatId: tgWebApp.initDataUnsafe.user.id,
    products: allCart.map(({ id, count }) => ({ id: +id, amount: count })),
    paymentType: 'crypto',
    tokenId,
  })

  return parserSendCartCrypto(result)
}
