import { useEffect, useState } from 'react'
import { tgWebApp } from '../const'

const useWebApp = () => {
  const [webApp, setWebApp] = useState({ isTelegram: false, utils: {} })

  useEffect(() => {
    const newWebApp = { isTelegram: false, utils: tgWebApp }

    if (tgWebApp.initDataUnsafe.auth_date) {
      newWebApp.isTelegram = true

      tgWebApp.onEvent('viewportChanged', () => {
        setWebApp({ isTelegram: true, utils: tgWebApp })
      })
    }

    setWebApp(newWebApp)
  }, [])

  return webApp
}

export default useWebApp
