import React from 'react'
import './content-select-payment-method-modal.scss'
import PropTypes from 'prop-types'
import iconCard from '../../../icons/icon-card.svg'
import iconCrypto from '../../../icons/icon-crypto.svg'

const ContentSelectPaymentMethodModal = ({ methods, onClick }) => {
  const renderMethods = () => methods.map((item) => {
    let srcIcon = ''
    let title = ''
    let altImg = ''

    switch (item) {
      case 'crypto':
        srcIcon = iconCrypto
        title = 'Криптокошелек'
        altImg = 'crypto'
        break
      case 'payment':
        srcIcon = iconCard
        title = 'Карта'
        altImg = 'crypto'
        break
      default:
        break
    }

    return (
      <div onClick={() => onClick(item)} key={item} className="select-pay-method__item">
        <img className="select-pay-method__item__icon" src={srcIcon} alt={altImg} />
        <div className="select-pay-method__item__title">{title}</div>
      </div>
    )
  })

  return (
    <div className="select-payment-method__container">
      {renderMethods()}
    </div>
  )
}

ContentSelectPaymentMethodModal.propTypes = {
  methods: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
}

ContentSelectPaymentMethodModal.defaultProps = {
  onClick: () => {},
}

export default ContentSelectPaymentMethodModal
