import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import router from './Routes'
import 'react-loading-skeleton/dist/skeleton.css'

import { store } from './store/index'
import { Web3Provider } from './context/web3/web3Provider'

function App() {
  return (
    <Provider store={store}>
      <Web3Provider>
        <RouterProvider router={router} />
      </Web3Provider>
    </Provider>
  )
}

export default App
