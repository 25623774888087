import React, { useState, memo } from 'react'
import './select-token-for-pay.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Connect from './Connect/Connect'
import useGetInitData from '../../hooks/useGetInitData'
import Pay from './Connect/Pay/Pay'

const SelectTokenForPay = () => {
  const [isLoadingMainBtn, setIsLoadingMainBtn] = useState(false)
  const navigate = useNavigate()
  const { shopId } = useParams()

  useGetInitData()

  return (
    <Connect onSetLoadingMainBtn={setIsLoadingMainBtn}>
      <Pay
        navigate={navigate}
        shopId={shopId}
        isLoadingMainBtn={isLoadingMainBtn}
        onSetIsLoadingMainBtn={setIsLoadingMainBtn}
      />
    </Connect>
  )
}

export default memo(SelectTokenForPay)
