import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectCatalogEntity } from '../../../store/slice/catalog'
import { checkExistImg } from '../../../utils/files'
import AddProduct from './AddProduct/AddProduct'
import { selectCartEntity } from '../../../store/slice/cart'

const Content = ({ productId }) => {
  const productById = useSelector(selectCatalogEntity(productId))
  const selectedProductFromCartById = useSelector(selectCartEntity(productId))
  const [srcImg, setSrcImg] = useState()

  useEffect(() => {
    if (productById) {
      checkExistImg(productById.images?.attachment?.filepath)
        .then((res) => setSrcImg(res.srcImg))
        .catch(() => setSrcImg(undefined))
    }
  }, [productById])

  if (!productById) {
    return <div>Товар не найден</div>
  }

  return (
    <div className={`product__wrapper ${selectedProductFromCartById?.count && 'product__wrapper__with-action'}`}>
      <div className="product__container">
        <div className="product__content">
          {srcImg
            ? <img src={srcImg} alt="The product" className="product__img" />
            : <div />}

          <div className="product__title">{productById.title}</div>
          <div className="product__description">{productById.description}</div>
        </div>

        <AddProduct
          productId={productId}
          productById={productById}
          selectedProductFromCartById={selectedProductFromCartById || { count: 0 }}
        />
      </div>
    </div>
  )
}

Content.propTypes = {
  productId: PropTypes.string.isRequired,
}

export default Content
