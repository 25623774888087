import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import unselectedRadioButton from '../../../icons/unselected-radio-btn.svg'
import selectedRadioButton from '../../../icons/selected-radio-btn.svg'
import usdtLogo from '../../../icons/usdt-logo.svg'
import bnbLogo from '../../../icons/bnb-logo.svg'
import btcLogo from '../../../icons/btc-logo.svg'
import busdLogo from '../../../icons/busd-logo.svg'
import './row-token.scss'

const CONST_TOKENS = {
  usdt: {
    logo: usdtLogo,
    symbol: 'usdt',
    name: 'Tether',
  },
  busd: {
    logo: busdLogo,
    symbol: 'busd',
    name: 'Binance USD',
  },
  btcb: {
    logo: btcLogo,
    symbol: 'btcb',
    name: 'Bitcoin',
  },
  bnb: {
    logo: bnbLogo,
    symbol: 'bnb',
    name: 'Binance Coin',
  },
}
const RowToken = ({ onClick, isSelected, tokenId }) => {
  const clsContainer = useMemo(() => {
    let cls = 'row-token__container'

    if (isSelected) {
      cls += ' row-token__container--selected'
    }

    return cls
  }, [isSelected])

  const infoToken = useMemo(() => CONST_TOKENS[tokenId.toLowerCase()] || { symbol: tokenId, name: tokenId }, [tokenId])

  return (
    <div onClick={onClick} className={clsContainer}>
      <img className="row-token__radio-button" src={isSelected ? selectedRadioButton : unselectedRadioButton} alt="radio" />

      {infoToken.logo && (
        <img className="row-token__token-logo" src={infoToken.logo} alt="Token Logo" />
      )}

      <div className="row-token__name">
        {infoToken.name}
        {' '}
        (
        {infoToken.symbol.toUpperCase()}
        )
      </div>
    </div>
  )
}

RowToken.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  tokenId: PropTypes.string.isRequired,
}

RowToken.defaultProps = {
  onClick: () => {},
  isSelected: false,
}

export default RowToken
