import { createSlice } from '@reduxjs/toolkit'

export const pay = createSlice({
  name: 'pay',
  initialState: {
    type: undefined,
    availableTypes: {},
  },
  reducers: {
    setType: (state, action) => ({ ...state, type: action.payload }),
    setAvailableTypes: (state, action) => ({ ...state, availableTypes: action.payload }),
  },
})

export const selectAvailableTypes = (state) => state.pay.availableTypes
export const selectAvailableTypesFiat = (state) => {
  const currencyFiat = state.pay?.availableTypes?.payment?.currency
  if (currencyFiat) {
    return currencyFiat
  }

  const cryptoFiat = state.pay?.availableTypes?.crypto?.fiat
  if (cryptoFiat) {
    return cryptoFiat
  }

  return {}
}
export const selectAvailableTypesCrypto = (state) => state.pay?.availableTypes?.crypto?.currency || []

export const { setType, setAvailableTypes } = pay.actions
