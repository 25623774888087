import { getPayUrl } from '../../../../api/rest/cart'
import { tgWebApp } from '../../../../const'

const openInvoice = ({
  onSuccess, onError, url,
}) => {
  tgWebApp.openInvoice(url, (event) => {
    switch (event) {
      case 'paid':
        onSuccess()
        break
      case 'failed':
        onError()
        break
      default:
        break
    }
  })
}
export const payTelegram = async ({
  shopId, allCart, onSuccess, onError,
}) => {
  try {
    const { data: { invoice: { ok, result } } } = await getPayUrl({
      shopId,
      chatId: tgWebApp.initDataUnsafe.user.id,
      products: allCart.map(({ id, count }) => ({ id: +id, amount: count })),
      paymentType: 'telegram',
    })

    if (ok) {
      openInvoice({ onSuccess, onError, url: result })
    }
  } catch (e) {
    console.error(e)
    onError()
    throw e
  }
}
