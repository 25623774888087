import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import {
  addItem, removeItem, updateItem,
} from '../../../../store/slice/cart'
import { selectAvailableTypesFiat } from '../../../../store/slice/pay'

const AddProduct = ({ productId, productById, selectedProductFromCartById }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { shopId } = useParams()
  const fiatCurrency = useSelector(selectAvailableTypesFiat)
  const [localCount, setCount] = useState(selectedProductFromCartById ? selectedProductFromCartById.count : 0)

  const onClickAdd = () => {
    if (selectedProductFromCartById && selectedProductFromCartById.count > 0) {
      dispatch(updateItem({ id: productId, count: selectedProductFromCartById.count + 1, price: productById.price }))
    } else {
      dispatch(addItem({
        id: productId, name: productById.name, price: productById.price, count: 1,
      }))
    }

    setCount((prevState) => prevState + 1)
  }

  const onClickAddProduct = (event, action = 'plus') => {
    event.preventDefault()

    const newCount = action === 'plus' ? localCount + 1 : localCount - 1

    if (newCount > -1) {
      setCount(newCount)
    }

    if (newCount === 0) {
      dispatch(removeItem(productId))

      return
    }
    if (localCount > 0) {
      dispatch(updateItem({ id: productId, count: newCount, price: productById.price }))
      return
    }

    dispatch(addItem({
      id: productId, title: productById.title, price: productById.price, count: newCount,
    }))
  }

  if (localCount > 0) {
    return (
      <div className="product__price-and-add">
        <div className="product__price-and-add__count__wrapper">
          <div className="product__price product__price--none-padding">
            <div className="product__price__text product__price__text--bold">
              {productById.price}
              {' '}
              {fiatCurrency?.symbol}
            </div>
          </div>

          <div className="product__price-and-add__count__action">
            <div
              onClick={(e) => onClickAddProduct(e, 'minus')}
              className="product__price-and-add__count__action__minus"
            >
              -
            </div>
            {localCount}
            <div
              onClick={(e) => onClickAddProduct(e)}
              className="product__price-and-add__count__action__add"
            >
              +
            </div>
          </div>
        </div>

        <div onClick={() => navigate(`/${shopId}/cart`)} className="product__btn-add product__btn-add--full-width">
          Перейти в корзину
        </div>
      </div>
    )
  }

  return (
    <div className="product__price-and-add">
      <div className="product__price">
        <div className="product__price__text product__price__text--bold">
          {productById.price}
          {' '}
          {fiatCurrency?.symbol}
        </div>

        <div onClick={onClickAdd} className="product__btn-add">
          Добавить
        </div>
      </div>
    </div>
  )
}

AddProduct.propTypes = {
  productId: PropTypes.string.isRequired,
  productById: PropTypes.object.isRequired,
  selectedProductFromCartById: PropTypes.object.isRequired,
}

export default AddProduct
