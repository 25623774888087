import React, { useEffect, memo, useState } from 'react'
import { bscTestnet, bsc } from 'wagmi/chains'
import {
  configureChains, createClient, useAccount, WagmiConfig,
} from 'wagmi'
import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum'
import { useWeb3Modal, Web3Modal } from '@web3modal/react'
import PropTypes from 'prop-types'
import { isDevelopment, WALLETCONNECT_ID, WALLETCONNECT_NAME } from '../../../const'
import SwitchNetwork from './SwitchNetwork/SwitchNetwork'
import Disconnect from './Disconnect'

const chains = []

if (isDevelopment) {
  chains.push(bscTestnet)
} else {
  chains.push(bsc)
}

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: WALLETCONNECT_ID }),
])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    chains,
    appName: WALLETCONNECT_NAME,
  }),
  provider,
})

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains)
const Connect = ({ children, onSetLoadingMainBtn }) => {
  const [isRefresh, setIsRefresh] = useState(true)

  const { isConnected } = useAccount({
    onDisconnect() {
      console.log('Disconnected')
      setIsRefresh(true)
    },
  })
  const { isOpen, open } = useWeb3Modal()

  useEffect(() => {
    (async () => {
      if (isOpen || isConnected || !isRefresh) return

      try {
        await open()

        setIsRefresh(false)
      } catch (error) {
        console.error('Open: ', error)
      }
    })()
  }, [open, isOpen, isConnected, isRefresh])

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Disconnect />
        <SwitchNetwork onSetLoadingMainBtn={onSetLoadingMainBtn} networkId={chains[0].id}>
          {children}
        </SwitchNetwork>
      </WagmiConfig>

      <Web3Modal
        projectId={WALLETCONNECT_ID}
        ethereumClient={ethereumClient}
      />
    </>
  )
}

Connect.propTypes = {
  children: PropTypes.node.isRequired,
  onSetLoadingMainBtn: PropTypes.func.isRequired,
}

export default memo(Connect)
