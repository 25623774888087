import React, { useEffect, memo } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import PropTypes from 'prop-types'
import MainPopupWithoutHeaderAction
from '../../../../components/MainPopupWithoutHeaderAction/MainPopupWithoutHeaderAction'

const SwitchNetwork = ({ children, networkId, onSetLoadingMainBtn }) => {
  const { isConnected } = useAccount()
  const { chain } = useNetwork()
  const switchNetwork = useSwitchNetwork({
    onMutate: () => onSetLoadingMainBtn(true),
    onSuccess: () => onSetLoadingMainBtn(false),
    onError: () => onSetLoadingMainBtn(true),
  })

  useEffect(() => {
    if (!isConnected || typeof switchNetwork.switchNetwork !== 'function' || switchNetwork.isLoading || switchNetwork.isError) {
      return
    }

    if (chain.id !== networkId) {
      switchNetwork.switchNetwork(networkId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, isConnected, networkId, switchNetwork.isLoading, switchNetwork.switchNetwork])

  return (
    <>
      <MainPopupWithoutHeaderAction
        isOpen={switchNetwork.isLoading}
        title="Switch Network"
      >
        Пожалуйста, перейдите в криптокошелек и подтвердите смену сети
      </MainPopupWithoutHeaderAction>

      {children}
    </>
  )
}

SwitchNetwork.propTypes = {
  children: PropTypes.node.isRequired,
  networkId: PropTypes.number.isRequired,
  onSetLoadingMainBtn: PropTypes.func.isRequired,
}

export default memo(SwitchNetwork)
