import React, { useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import RowToken from '../../../RowToken/RowToken'
import { selectAvailableTypesCrypto } from '../../../../../store/slice/pay'

const RowTokenMemo = React.memo(RowToken)

const ListTokens = ({ selectedToken = { name: '' }, onSetSelectedToken }) => {
  const availableTokens = useSelector(selectAvailableTypesCrypto)

  useEffect(() => {
    onSetSelectedToken(availableTokens[0])
  }, [availableTokens, onSetSelectedToken])

  return (
    <div className="select-token-for-pay__list-tokens__container">
      {availableTokens.map((token) => {
        const tokenLowerCase = token.name.toLowerCase()
        const selectedTokenLoweCase = selectedToken.name.toLowerCase()

        return (
          <div className="select-token-for-pay__list-tokens__item" key={token.id}>
            <RowTokenMemo
              onClick={() => onSetSelectedToken(token)}
              tokenId={token.name}
              isSelected={selectedTokenLoweCase === tokenLowerCase}
            />
          </div>
        )
      })}
    </div>
  )
}

ListTokens.propTypes = {
  selectedToken: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  onSetSelectedToken: PropTypes.func.isRequired,
}

export default memo(ListTokens)
