import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useMatches, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ContentSelectPaymentMethodModal from './ContentSelectPaymentMethodModal/ContentSelectPaymentMethodModal'
import useWebApp from '../../hooks/useWebApp'
import DumbMainContainer from './DumbMainContainer/DumbMainContainer'
import { initData, reducerForMainContainer } from './reducerForMainContainer'
import { selectAllCart, selectTotalCart, setEmpty } from '../../store/slice/cart'
import MainPopup from '../../components/MainPopup/MainPopup'
import { payTelegram as paymentTelegram } from './payMethods/telegram'
import { selectCatalogEntity } from '../../store/slice/catalog'
import { selectLocale } from '../../store/slice/locale'
import { selectAvailableTypes } from '../../store/slice/pay'
import SuccessPay from './SuccessPayModal/SuccessPay'

const MainContainer = ({
  children,
}) => {
  const [settingsMainContainer, dispatch] = useReducer(reducerForMainContainer, initData)
  const [isShowModalSelectType, setIsShowModalSelectType] = useState(false)
  const [isLoadingMainBtn, setIsLoadingMainBtn] = useState(false)
  const [isShowSuccessPay, setIsShowSuccessPay] = useState(false)

  const dispatchRedux = useDispatch()
  const allCart = useSelector(selectAllCart)
  const totalCart = useSelector(selectTotalCart)
  const { productId } = useParams()
  const productById = useSelector(selectCatalogEntity(productId))
  const location = useMatches()
  const navigate = useNavigate()
  const { shopId } = useParams()
  const sliceLocale = useSelector(selectLocale)
  const slicePayAvailableTypes = useSelector(selectAvailableTypes)
  const tgWebApp = useWebApp()

  const onClosePopup = useCallback(() => {
    setIsShowModalSelectType(false)
  }, [])

  const payTelegram = useCallback(async () => {
    setIsLoadingMainBtn(true)

    const onSuccess = () => {
      setIsShowSuccessPay(true)
    }
    const onError = () => {
      tgWebApp.utils.showAlert(sliceLocale.errorPay)
    }

    try {
      await paymentTelegram({
        shopId, allCart, onSuccess, onError,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoadingMainBtn(false)
      onClosePopup()
    }
  }, [allCart, onClosePopup, shopId, sliceLocale.errorPay, tgWebApp.utils])

  const payCrypto = useCallback((_navigate) => {
    onClosePopup()
    _navigate('select-token-for-pay')
  }, [onClosePopup])

  const onSelectTypePay = useCallback((_navigate) => (type) => {
    if (type === 'payment') {
      return payTelegram(_navigate)
    }

    return payCrypto(_navigate)
  }, [payCrypto, payTelegram])

  const onClickPay = useCallback((_navigate) => () => {
    if (Object.keys(slicePayAvailableTypes).length > 1) {
      setIsShowModalSelectType(true)

      return
    }

    onSelectTypePay(_navigate)(Object.keys(slicePayAvailableTypes)[0])
  }, [onSelectTypePay, slicePayAvailableTypes])

  // The need to always expand webApp
  useEffect(() => {
    if (tgWebApp.isTelegram && !tgWebApp.utils.isExpanded) {
      tgWebApp.utils.expand()
    }
  }, [tgWebApp])

  // Set settings to main container
  useEffect(() => {
    dispatch({
      type: location[location.length - 1].id,
      shopId,
      localeButtons: { mainButton: 'Перейти в корзину', addProductButton: sliceLocale.addToCart },
      isHandleHiddenMinButton: totalCart < 1,
      navigate,
      onHandlePay: onClickPay(navigate),
      isHandleLoadingMainBtn: isLoadingMainBtn,
    })
  }, [
    isLoadingMainBtn,
    location,
    navigate,
    onClickPay,
    shopId,
    sliceLocale.addProduct,
    sliceLocale.addToCart,
    sliceLocale.forBuy,
    sliceLocale.localeButtons,
    totalCart,
  ])

  return (
    <DumbMainContainer {...settingsMainContainer} title={productById ? productById.title : settingsMainContainer.title}>
      <SuccessPay
        isOpen={isShowSuccessPay}
        onClosePopup={() => {
          dispatchRedux(setEmpty())
          navigate(`/${shopId}`)
          setIsShowSuccessPay(false)
        }}
      />
      <MainPopup title="Выберите способ оплаты" isOpen={isShowModalSelectType} onClose={onClosePopup}>
        <ContentSelectPaymentMethodModal
          methods={Object.keys(slicePayAvailableTypes)}
          onClick={onSelectTypePay(navigate)}
        />
      </MainPopup>
      {children}
    </DumbMainContainer>
  )
}

MainContainer.propTypes = {
  children: PropTypes.node,
}

const MainContainerMemo = React.memo(MainContainer)

export default MainContainerMemo
